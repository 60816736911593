<template>
  <div class="select-bg">
    <div class="select-label">{{ label }}</div>
    <div class="select-content-bg" v-click-outside="hideModalOptions">
      <div
        :class="[showModal ? 'select-content__open' : '', 'select-content']"
        @click="openModalOptions"
      >
        <i class="icon-filter-secondary"></i>
        <div class="select-content-placeholder">{{ placeholder }}</div>
      </div>
      <ModalSelect
        v-if="showModal"
        :value="value"
        :placeholder="placeholder"
        :itemName="itemName"
        :entity="entity"
        :maxCount="maxCount"
        :multiple="multiple"
        :actionEntity="actionEntity"
        :hiddenItems="hiddenItems"
        :createMode="createMode"
        :createEntity="createEntity ? createEntity : 'actionCreate'"
        :channelUsers="channelUsers"
        :is_deleted="is_deleted"
        :filterType="filterType"
        @pushOptionToValue="pushOptionToValue"
        @deleteOptionToValue="deleteOptionToValue"
        @pushAllOption="pushAllOption"
        @deleteAllOption="deleteAllOption"
        :isAdminRegulation="isAdminRegulation"
        :isRegulation="isRegulation"
        :ispGroup="ispGroup"
      />
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
const ModalSelect = () => import("@/components/Widgets/Select/Select2.1/ModalSelect");

export default {
  name: "Select2.0",
  mixins: [],
  props: [
    "entity",
    "actionEntity",
    "multiple",
    "placeholder",
    "itemName",
    "keyValue",
    "maxCount",
    "label",
    "editValue",
    "findId",
    "createMode",
    "hiddenItems",
    "createEntity",
    "channelUsers",
    "is_deleted",
    "filterType",
    "disabled",
    "isAdminRegulation",
    "isRegulation",
    "ispGroup"
  ],
  components: { ModalSelect },
  data() {
    return {
      value: [],
      showModal: false
    };
  },
  beforeDestroy() {
    this.$root.$off("hideSelectModal");
    this.$root.$off(`insertValue${this.keyValue}`);
    this.$root.$off("checkSelect");
    this.$root.$off("deleteAllOptionsSelect");
  },
  mounted() {
    if (this.editValue && this.editValue.length) {
      this.value = this.editValue;
    }
    this.$root.$on("hideSelectModal", () => {
      this.showModal = false;
    });
    this.$root.$on(`insertValue${this.keyValue}`, (val) => {
      this.value = val;
    });
    this.$root.$on("deleteAllOptionsSelect", () => {
      this.deleteAllOption();
    });
    this.$root.$on("checkSelect", (val) => {
      this.checkSelect(val);
    });
  },
  methods: {
    openModalOptions() {
      if (!this.disabled) {
        this.showModal = !this.showModal;
      } else {
        return false;
      }
    },
    hideModalOptions() {
      this.showModal = false;
    },
    deleteAllOption() {
      if (!this.disabled) {
        event.stopPropagation();
        this.value = [];
      } else {
        return false;
      }
    },
    pushAllOption(options) {
      this.value = JSON.parse(JSON.stringify(options));
      if (this.hiddenItems.length) {
        for (let i in this.hiddenItems) {
          let index = this.value.findIndex((val) => val.id === this.hiddenItems[i].id);
          if (index !== -1) this.value.splice(index, 1);
        }
      }
    },
    pushOptionToValue(option) {
      if (this.multiple) {
        if (this.maxCount) {
          if (this.value.length < this.maxCount) {
            this.value.push(option);
          } else {
            this.$root.$emit("showNotification", {
              type: "error",
              timeout: 3000,
              label: `Максимальное кол-во ${this.maxCount} !`
            });
          }
        } else {
          this.value.push(option);
        }
      } else {
        if (this.value.length) {
          this.value.splice(0, 1);
          this.value.push(option);
          this.showModal = false;
        } else {
          this.value.push(option);
          this.showModal = false;
        }
      }
    },
    checkSelect(id) {
      return this.value.find((item) => item.id === id);
    },
    deleteOptionToValue(option) {
      let index = this.value.findIndex((val) => val.id === option.id);
      this.value.splice(index, 1);
    }
  },
  computed: {},
  watch: {
    value() {
      this.$root.$emit(`changeSelectValue:${this.keyValue}`, this.value);
    }
  },
  validations: {},
  directives: {
    ClickOutside
  }
};
</script>
<style scoped lang="scss">
@import "~@/assets/base/color";
@import "~@/assets/base/breakpoints/breakpoints";
@import "~@/assets/base/color/colors.scss";

.icon-cross,
.icon-up,
.icon-filter-secondary {
  color: $icon-subdued;
  font-size: 16px;
  transition: 0.3s;
}

.select-bg {
  flex-shrink: 0;
  width: 100%;
  min-width: 210px;
  max-width: 240px;

  .select-label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: $text-default;
  }

  .select-content-bg {
    position: relative;
    width: 100%;
    height: auto;

    .select-content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      user-select: none;
      flex-shrink: 0;
      padding: 10px 20px;
      border-radius: 20px;
      background-color: $bg-gray-75;

      .select-content-placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: $text-default;
        margin-left: 8px;
      }

      &__open {
        & + .modal-items {
          transform: scale(1);
        }
      }

      .select-content-value-bg {
        display: flex;
        align-items: center;
        width: calc(100% - 16px);
        flex-shrink: 0;

        .select-content-value {
          border-radius: 3px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          padding: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          background-color: $background-default;
          color: $text-highlight;
        }

        .select-content-count {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 13px;
          color: $color-background;
          margin: 0 6px 0 4px;
          background-color: $action-primary;
          padding: 2px 3px;
          border-radius: 3px;
        }

        .select-content-placeholder {
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          color: $text-subdued;
        }
      }
    }
  }
}
</style>
